<!-- 基础资料 -->
<template>
  <div class="orderTest-container">
    <el-dialog :visible.sync="admindata" title="基础资料" center width="60%">
      <div>
        <el-form ref="form" :model="form" inline label-width="120px">
          <el-form-item label="客户ID：">
            <el-input
              v-model="form.id"
              placeholder="客户ID"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="企业名称：">
            <el-input
              v-model="form.dealer_name"
              placeholder="企业名称"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="联系人：">
            <el-input
              v-model="form.boss"
              placeholder="联系人"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="电话：">
            <el-input
              v-model="form.mobile"
              placeholder="电话"
              disabled
            ></el-input>
          </el-form-item>
        </el-form>
        <el-table stripe :data="tableData">
          <el-table-column
            v-for="(list, index) in columne"
            :key="index"
            :label="list.label"
            :align="list.align"
            :prop="list.prop"
            width=""
          ></el-table-column>
          <el-table-column align="center" label="操作" width="280">
            <template #default="{ $index, row }">
              <!-- {{ row.btn }} -->
              <el-button
                v-for="btn in row.btn"
                :key="btn"
                type="text"
                @click="handleBtnClick(btn, $index, row)"
              >
                {{ btn }}
              </el-button>
              <!-- <el-button
                v-show="$index != 4"
                type="text"
                @click="handleruploder($index)"
              >
                导入
              </el-button>
              <el-button
                v-show="$index != 4"
                type="text"
                @click="handlerdownFile($index)"
              >
                导出
              </el-button>
              <el-button
                v-show="$index == 0 || $index == 1"
                type="text"
                @click="ahndlerdestroy($index, 1)"
              >
                清空已封存
              </el-button>
              <el-button
                v-show="$index == 0 || $index == 1"
                type="text"
                @click="ahndlerdestroy($index, 2)"
              >
                清空全部
              </el-button>
              <el-button v-show="$index == 4" type="text" @click="handlerundo">
                恢复初始
              </el-button> -->
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="clear">关闭</el-button>
      </div>
    </el-dialog>
    <upload-exel ref="upload"></upload-exel>
  </div>
</template>

<script>
  import { postAction, downloadFile } from '@/api/Employee'
  // import  {uploadExel} from './'
  export default {
    components: {
      uploadExel: () => import('./uploadExel.vue'),
    },
    data() {
      return {
        admindata: false,
        form: {
          id: '',
          dealer_name: '',
          boss: '',
          mobile: '',
          cust_num: '',
          goods_num: '',
          depot_num: '',
          arrears_num: '',
        },
        tableData: [],
        columne: [
          {
            label: '项目',
            align: 'center',
            prop: 'name',
            width: '',
          },
          {
            label: '数量',
            align: 'center',
            prop: 'num',
            width: '',
          },
          {
            label: '最后维护时间',
            align: 'center',
            prop: 'last_time',
            width: '',
          },
          {
            label: '维护人',
            align: 'center',
            prop: 'last_user',
            width: '',
          },
        ],
        url: {
          billlist: '/superAdmin/user-data/detail',
          undo: '/superAdmin/user-data/clear',
          file: '/baseAdmin/customer-info/export',
        },
      }
    },

    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
      handleruploder(index) {
        console.log(index, '导入')
        switch (index) {
          case 0:
            //1商品2客户3盘点数据4期初库存5期初欠款6价格方案产品7导入提成
            this.$refs.upload.data.type = 2
            this.$refs.upload.data.dealer_id = this.basisID
            break
          case 1:
            this.$refs.upload.data.type = 1
            this.$refs.upload.data.dealer_id = this.basisID
            break
          case 2:
            this.$refs.upload.data.type = 4
            this.$refs.upload.data.dealer_id = this.basisID
            break
          case 3:
            this.$refs.upload.data.type = 5
            this.$refs.upload.data.dealer_id = this.basisID
            break
        }
        this.$refs.upload.showDialog = true
      },
      clear() {
        this.admindata = false
        this.$refs['form'].resetFields()
      },
      headlerData(id) {
        this.basisID = id
        this.handlerlist()
      },
      handlerlist() {
        postAction(this.url.billlist, { id: this.basisID })
          .then((res) => {
            console.log(res)
            this.form = res.data
            this.tableData = res.data.list
            // this.tableData[0].Quantity = res.data.cust_num
            // this.tableData[1].Quantity = res.data.goods_num
            // this.tableData[2].Quantity = res.data.depot_num
            // this.tableData[3].Quantity = res.data.arrears_num
          })
          .catch((err) => {
            console.log(err)
          })
      },
      handlerundo() {
        postAction(this.url.undo, { id: this.basisID })
          .then((res) => {
            console.log(res)
            this.$message({ type: 'success', message: res.msg })
            this.handlerlist()
          })
          .catch((err) => {
            console.log(err)
          })
      },
      ahndlerdestroy(index, e, row) {
        let classd = 0
        index == 0 ? (classd = 1) : (classd = 2)
        console.log('a', this.basisID)
        if (e == 2) classd = row.id
        postAction(this.url.undo, { id: this.basisID, class: classd, type: e })
          .then((res) => {
            console.log(res)
            this.$message({ type: 'success', message: res.msg })
            this.handlerlist()
          })
          .catch((err) => {
            console.log(err)
          })
      },
      handlerdownFile(index) {
        let title = ''
        let url = ''
        index == 0 ? (title = '客户资料.xls') : (title = '商品资料.xls')
        index == 0
          ? (url = this.url.file)
          : (url = 'baseAdmin/goods/export-goods')

        downloadFile(url, title, { dealer_id: this.basisID })
      },
      handleBtnClick(btn, index, row) {
        console.log(btn, index)
        switch (btn) {
          case '导入':
            this.handleruploder(index)
            break
          case '导出':
            this.handlerdownFile(index)
            break
          case '清空全部':
            this.ahndlerdestroy(index, 2, row)
            break
          case '清空已封存':
            this.ahndlerdestroy(index, 1)
            break
          case '恢复初始':
            this.handlerundo()
            break
          case '清空成本价':
            this.handlerchengben(row)
            break
        }
      },
      handlerchengben(row) {
        postAction('/superAdmin/user-data/clear', {
          id: this.basisID,
          type: 4,
          class: 2,
        }).then((res) => {
          this.$message({ type: 'success', message: res.msg })
          this.handlerlist()
        })
      },
    },
  }
</script>
<style lang="scss" scoped></style>
