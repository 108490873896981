var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderTest-container" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.admindata,
            title: "基础资料",
            center: "",
            width: "60%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.admindata = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.form,
                    inline: "",
                    "label-width": "120px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "客户ID：" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "客户ID", disabled: "" },
                        model: {
                          value: _vm.form.id,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "id", $$v)
                          },
                          expression: "form.id",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "企业名称：" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "企业名称", disabled: "" },
                        model: {
                          value: _vm.form.dealer_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "dealer_name", $$v)
                          },
                          expression: "form.dealer_name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "联系人：" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "联系人", disabled: "" },
                        model: {
                          value: _vm.form.boss,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "boss", $$v)
                          },
                          expression: "form.boss",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "电话：" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "电话", disabled: "" },
                        model: {
                          value: _vm.form.mobile,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "mobile", $$v)
                          },
                          expression: "form.mobile",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-table",
                { attrs: { stripe: "", data: _vm.tableData } },
                [
                  _vm._l(_vm.columne, function (list, index) {
                    return _c("el-table-column", {
                      key: index,
                      attrs: {
                        label: list.label,
                        align: list.align,
                        prop: list.prop,
                        width: "",
                      },
                    })
                  }),
                  _c("el-table-column", {
                    attrs: { align: "center", label: "操作", width: "280" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var $index = ref.$index
                          var row = ref.row
                          return _vm._l(row.btn, function (btn) {
                            return _c(
                              "el-button",
                              {
                                key: btn,
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleBtnClick(btn, $index, row)
                                  },
                                },
                              },
                              [_vm._v(" " + _vm._s(btn) + " ")]
                            )
                          })
                        },
                      },
                    ]),
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [_c("el-button", { on: { click: _vm.clear } }, [_vm._v("关闭")])],
            1
          ),
        ]
      ),
      _c("upload-exel", { ref: "upload" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }